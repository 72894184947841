@import url("https://fonts.googleapis.com/css?family=Roboto");
html,
body,
#root,
#root > div {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Disabling user text selection by long press for mobile browsers */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Disabling link navigation popup by long press on links in iOS */
  -webkit-touch-callout: none;
}

textarea,
select,
input,
button {
  outline: none;
}

pre {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: "Roboto", sans-serif;
}

input[type="reset"],
input[type="submit"],
button {
  padding: 0;
  outline: none;
  background: none;
  border: none;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
